import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from "../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import { container, results } from './zabka-contest-closed-page.module.scss';
import { IPage } from '../models/page.model';
import { IFlavour } from '../models/flavour';
import { IQueryAllResult } from "../models/query-all-result.model";
import { getNodes } from '../utils/get-nodes';
import { getFlavourResults } from "../utils/get-flavour-results";

import ZabkaContestLayout from "../layouts/zabka-contest-layout";
import ContestResults from "../components/organisms/contest-results";

interface IZabkaContestClosedPageProps {
    readonly data: {
        page: IPage | null;
        allStrapiZabkaFlavour: IQueryAllResult<IFlavour>;
    };
}

const ZabkaContestClosedPage: React.FC<IZabkaContestClosedPageProps> = ({ data }) => {
    const { t } = useI18next();
    const { page, allStrapiZabkaFlavour } = data;
    const flavours = getNodes(allStrapiZabkaFlavour);

    const queryResults = '1:13,2:9,3:15,4:7,5:1,6:17,7:22,8:16';
    const resultFlavours = getFlavourResults(flavours, queryResults);

    return (
        <ZabkaContestLayout
            page={page}
            className={container}
            headerProps={{
                titleFirstLine: t('zabka.contest.closed.title.1'),
                titleSecondLine: t('zabka.contest.closed.title.2'),
                children: <>{t('zabka.contest.closed.content.1')}<br/>{t('zabka.contest.closed.content.2')}</>
            }}
        >
            <ContestResults
                titleFirstLine={t('zabka.contest.closed.result.1')}
                titleSecondLine={t('zabka.contest.closed.result.2')}
                className={results}
                flavourResults={resultFlavours}
            />
        </ZabkaContestLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        allStrapiZabkaFlavour(filter: {locale: { eq: $language }, isDummyContent: { eq: false }}) {
            edges {
                node {
                    name
                    value
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED, quality: 99, width: 125)
                            }
                        }
                        alternativeText
                    }
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default ZabkaContestClosedPage;
